/* require dependencies */
import { globalVariables, route } from "../types";
import { Switch, Route } from 'react-router-dom'
import routes from ".";

/* routing function */
const routing = (globalVariables: globalVariables) => {
    return (
        <Switch>
            {
                routes.map((route: route, index: number) => {

                    // unprotected routes
                    if (route.guest && !globalVariables.state.authenticated)
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                render={(props) => (<route.component {...props} globalVariables={globalVariables} />)}
                                exact
                            />
                        )

                    // protected routes
                    if (!route.guest && globalVariables.state.authenticated) {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                render={(props) => (<route.component {...props} globalVariables={globalVariables} />)}
                                exact
                            />
                        )
                    }

                    return null

                })
            }
        </Switch>
    )
}

export default routing