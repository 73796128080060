/* requiring dependencies */
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'

/* requiring  styles */
import "./sass/light.scss"

/* getting document root element */
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

/* rendering view to the root element */
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

