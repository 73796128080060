/* dependencies */
import React from "react"
import { Icon } from "./elements"
import menus from "../config/menu"
import { auth, menu, subMenu } from "../types"
import { Link } from "react-router-dom"
import Breadcrumb from "./breadcrumb"
import { applicationName } from "../helpers"

/* sidebar */
const Sidebar: React.FunctionComponent<auth> = React.memo((props: auth) => {

    const [activeLink, setActiveLink] = React.useState<string>(window.location.pathname)
    const [activMenu, setActiveMenu] = React.useState<number>(-1)
    const [icon, setIcon] = React.useState<string>("dashboard")
    const [title, setTitle] = React.useState<string>("dashboard")

    window.onclick = (event: any): void => {
        // getting current pathname
        const currentPathname: string = window.location.pathname

        // changing active nav link
        setActiveLink(currentPathname)

        // update breadcrumb
        updateBreadcrumb()

        // close active sidebar content
        if ((event.target.tagName !== "A") && (event.target.tagName !== "SPAN") && (event.target.tagName !== "I"))
            setActiveMenu(-1)

    }

    // update breadcrumb
    function updateBreadcrumb(): void {
        const currentPathname: string = window.location.pathname
        // eslint-disable-next-line
        menus.map((menu: menu) => {
            if (currentPathname.includes(menu.title.replace(/ /g, "-").toLowerCase())) {
                setTitle(menu.title)
                setIcon(menu.icon)
            }
        })
    }

    React.useEffect(() => {
        updateBreadcrumb()
        // eslint-disable-next-line
    }, [])


    // function for toggling sidebar content
    const toggleSidebarContent = (index: number): void => {
        // toggle sibar nav content
        if (index === activMenu)
            setActiveMenu(-1)
        else
            setActiveMenu(index)
    }

    // function for rendering menu
    const renderMenu = React.useCallback(() => {
        return menus.map((menu: menu, index: number) => {
            if (menu.visible)
                return (
                    <li className="nav-item" key={index} onClick={() => {
                        toggleSidebarContent(index)
                        if (!menu.hasSubMenu) {
                            props.closeSidebar()
                            if (menu.title !== "dashboard")
                                props.unMount()
                        }
                    }}>
                        <Link to={menu.link} className={`nav-link ${activeLink.includes(menu.title.toLowerCase().replace(/ /g, "-")) ? "active" : ""}`}>
                            <Icon name={menu.icon} type="round" />
                            <span>{menu.title}</span>
                        </Link>
                        {
                            menu.hasSubMenu && menu.subMenu
                                ?
                                <ul className={`nav-content ${activMenu === index ? "show" : ""}`}>
                                    {
                                        menu.subMenu.map((subMenu: subMenu, indexTwo: number) => {
                                            if (subMenu.visible)
                                                return (
                                                    <li key={indexTwo}>
                                                        <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                            props.closeSidebar()
                                                            props.unMount()
                                                        }}>
                                                            <Icon name="chevron_right" type="round" />
                                                            <span>{subMenu.title}</span>
                                                        </Link>
                                                    </li>
                                                )
                                            else
                                                return null
                                        })
                                    }
                                </ul>
                                : null
                        }
                    </li>
                )
            else
                return null
        })
        // eslint-disable-next-line
    }, [activeLink, activMenu])

    // returning component view
    return (
        <>
            <Breadcrumb icon={icon} title={title} toggleSidebar={props.toggleSidebar} authenticate={props.authenticate} />
            <aside id="sidebar" className="sidebar">
                <i className="material-icons-round toggle-sidebar-btn" onClick={props.toggleSidebar}>menu_open</i>
                <Link to="/" className="logo-container">
                    <Icon name="sms" type="round" />
                    <div className="username">{applicationName}</div>
                </Link>
                <ul className="sidebar-nav" id="sidebar-nav">
                    {renderMenu()}
                    <li className="nav-item">
                        <Link to="#" className="nav-link" onClick={() => props.authenticate("logout")}>
                            <Icon name="logout" type="round" />
                            <span>Logout</span>
                        </Link>
                    </li>

                </ul>
                <li className="version-container">
                    <span className="title">v</span>
                    <span className="version">1.0.0</span>
                </li>
            </aside>
        </>
    )

})

/* exporting component */
export default Sidebar