/* dependencies */
import React from "react"
import { getInfo } from "../helpers"
import { Icon } from "./elements"

type breadcrumb = {
    icon: string
    title: string
    toggleSidebar(): void
    authenticate(action: "logout"): void
}

/* breadcrumb memorized functional component */
const Breadcrumb: React.FunctionComponent<breadcrumb> = React.memo((props: breadcrumb) => (
    <div className="breadcrumb">
        <div className="section" onClick={props.toggleSidebar}>
            <Icon name={props.icon} type="round" />
            <span className="title">{props.title}</span>
        </div>
        <div className="section right-section" onClick={() => props.authenticate("logout")}>
            <Icon name={"alternate_email"} type="round" />
            <span className="title">{getInfo("user", "userName")}</span>
        </div>
    </div>
))

/* exporting component */
export default Breadcrumb