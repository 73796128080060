/* requiring dependencies */
import { reducerAction, state } from "../types";
import initialState from "./state";

/**
 * application reducer function
 * receives application old state, state key and its new value
 * returns new state
 * */

/* creating application reducer */
const reducer = (state: state, reducerAction: reducerAction): state => {
    try {

        // defining action type and its value (not necessary)
        const type = reducerAction.type
        const value = reducerAction.value[type]

        // checking wether reducer action types contains "Error" keyword so that we can change disabled state to true
        if (type.includes("Error"))
            // copy the previous state then update only the received keywords and set disabled to true
            return { ...state, [type]: value, disabled: true }

        // checking for action that are'nt allowed to change disabled state
        if ((type === "authenticated") || (type === "disabled") || (type === "loading"))
            // copy the previous state then update only the received keywords
            return { ...state, [type]: value }

        if ((type === "all"))
            // return initial state
            return { ...initialState, authenticated: state.authenticated ? true : false, notification: state.notification, onlineUsers: state.onlineUsers }

        // copy the previous state then update only the received keywords, set disabled to true and update "Error" field with empty string
        return { ...state, [type]: value, [`${type}Error`]: "", disabled: false }

    } catch (error) {

        // returning old state dont change anything
        return state

    }
}

/* exporting application reducer */
export default reducer