/* dependencies */
import React from "react"

/* loader */
const Loader: React.FunctionComponent = React.memo(() => (
    <div className="loader-container">
        <div className="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

))

/* exporting component */
export default Loader