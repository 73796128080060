/* dependencies */
import React from "react"
import { actionButton, button, floatingButton } from "../types"
import { Link } from "react-router-dom"
import { Icon } from "./elements"

/* button */
export const Button: React.FunctionComponent<button> = React.memo((props: button) => (
    <button
        onClick={props.onClick}
        className={`btn ${props.disabled || props.loading ? "disabled" : ""}`}
        disabled={props.disabled || props.loading}
    >
        {props.loading ? "Loading" : props.disabled ? "Error" : props.title}
    </button>
))

/* floating button */
export const FloatingButton: React.FunctionComponent<floatingButton> = React.memo((props: floatingButton) => (
    <div className={`floating-button ${props.class}`}>
        <Link to={props.to} onClick={props.onClick} data-tooltip={props.tooltip}>
            <Icon name={props.icon} type="round" />
        </Link>
    </div>
))

/* table action button */
export const ActionButton: React.FunctionComponent<actionButton> = React.memo((props: actionButton) => (
    <Link
        to={props.to}
        className={`button ${props.type}`}
        data-tooltip={props.tooltip}
        data-position={props.position}
        onClick={props.onClick}
    >
        <Icon name={props.icon} type="round" rotate={props.rotate} />
    </Link>
))