/* dependencies */
import { commonCondition, getInfo, isAdmin } from "."

// get backend condition
const getBackendCondition = (name: string): object => {

    // initial condition
    let condition: object = isAdmin ? { ...commonCondition } : { ...commonCondition, user: getInfo("user", "_id") }

    // removing name whitespace
    name = name.trim()

    switch (name) {

        // common
        case "deleted":
            return { ...condition, deleted: true}

        // vendors
        case "approved":
            return { ...condition, status: name }
        case "denied":
            return { ...condition, status: name }
        case "disabled":
            return { ...condition, status: name }
        case "requested":
            return { ...condition, status: name }
        case "in progress":
            return { ...condition, status: "in_progress" }
        case "out of balance":
            return { ...condition, balance: { $lte: 0 } }
        case "with balance":
            return { ...condition, balance: { $gt: 0 } }

        // message
        case "scheduled":
            return { ...condition, status: "scheduled" }
        case "sent":
            return { ...condition, status: "sent" }
        case "API":
            return { ...condition, status: "API" }

        // user
        case "admins":
            return { ...condition, accountType: "_a_d_m_i_n_" }
        case "normal users":
            return { ...condition, accountType: "user" }
        case "developers":
            return { ...condition, accountType: "developer" }
        case "agents":
            return { ...condition, accountType: "agent" }
        case "with 2FA":
            return { ...condition, twoFactorAuthenticationEnabled: true }
        case "without 2FA":
            return { ...condition, twoFactorAuthenticationEnabled: false }
        case "verified":
            return { ...condition, phoneNumberVerified: true }
        case "not verified":
            return { ...condition, phoneNumberVerified: false }

        // payment
        case "canceled":
            return { ...condition, status: "canceled" }
        case "incoming":
            return isAdmin ? { ...condition, status: "incoming" } : { ...condition, status: "outgoing" }
        case "outgoing":
            return isAdmin ? { ...condition, status: "outgoing" } : { ...condition, status: "incoming" }
        case "incoming paid":
        case "outgoing paid":
            return { ...condition, status: "outgoing_paid" }


        default: return condition

    }

}

export default getBackendCondition