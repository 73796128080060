/* require dependencies */
import { lazy } from "react"
import { route } from "../types";

/* array of routes */
const routes: route[] = [

    // user routes (unauthenticated)
    {
        path: "/login",
        component: lazy(() => import("../pages/user/login")),
        guest: true
    },
    {
        path: "/forgot-password",
        component: lazy(() => import("../pages/user/forgot-password")),
        guest: true
    },
    {
        path: "/verification-code",
        component: lazy(() => import("../pages/user/verification-code")),
        guest: true
    },
    {
        path: "/change-password",
        component: lazy(() => import("../pages/user/change-password")),
        guest: true
    },
    {
        path: "/register",
        component: lazy(() => import("../pages/user/register")),
        guest: true
    },
    {
        path: "*",
        component: lazy(() => import("../pages/home/")),
        guest: true
    },

    // authenticated routes
    // user routes
    {
        path: "/user/form",
        component: lazy(() => import("../pages/user/form")),
        guest: false
    },
    {
        path: "/user/list",
        component: lazy(() => import("../pages/user/list")),
        guest: false
    },
    {
        path: "/profile/edit",
        component: lazy(() => import("../pages/user/form")),
        guest: false
    },
    {
        path: "/profile/view",
        component: lazy(() => import("../pages/user/view")),
        guest: false
    },

    {
        path: "/profile/password",
        component: lazy(() => import("../pages/user/password")),
        guest: false
    },
    {
        path: "/profile/verify",
        component: lazy(() => import("../pages/user/verify")),
        guest: false
    },

    // vendor routes
    {
        path: "/vendor/form",
        component: lazy(() => import("../pages/vendor/form")),
        guest: false
    },
    {
        path: "/vendor/list",
        component: lazy(() => import("../pages/vendor/list")),
        guest: false
    },
    {
        path: "/vendor/view",
        component: lazy(() => import("../pages/vendor/view")),
        guest: false
    },
    {
        path: "/vendor/sharing",
        component: lazy(() => import("../pages/vendor/sharing")),
        guest: false
    },

    // contact routes
    {
        path: "/contact/form",
        component: lazy(() => import("../pages/contact/form")),
        guest: false
    },
    {
        path: "/contact/list",
        component: lazy(() => import("../pages/contact/list")),
        guest: false
    },

    // group
    {
        path: "/group/form",
        component: lazy(() => import("../pages/group/form")),
        guest: false
    },
    {
        path: "/group/list",
        component: lazy(() => import("../pages/group/list")),
        guest: false
    },

    // message routes
    {
        path: "/message/form",
        component: lazy(() => import("../pages/message/form")),
        guest: false
    },
    {
        path: "/message/list",
        component: lazy(() => import("../pages/message/list")),
        guest: false
    },

    // payment routes
    {
        path: "/payment/form",
        component: lazy(() => import("../pages/payment/form")),
        guest: false
    },
    {
        path: "/payment/list",
        component: lazy(() => import("../pages/payment/list")),
        guest: false
    },

    // earning routes
    {
        path: "/earning",
        component: lazy(() => import("../pages/earning")),
        guest: false
    },

    // developer routes
    {
        path: "/developer",
        component: lazy(() => import("../pages/developer")),
        guest: false
    },

    // all authenticated routes routes
    {
        path: "*",
        component: lazy(() => import("../pages/dashboard")),
        guest: false
    },
]

export default routes;