/* requiring dependencies */
import React from "react"
import * as XLSX from "xlsx"
import FileSaver from "file-saver"
import { backendStatus, createOrUpdate, dispatch, mount, readOrDelete, serverResponse, state } from "../types"
import { apiV1, applicationName, commonCondition, encryption, getInfo, isAdmin, serverURL, storage, text } from "."
import getBackendCondition from "./backendCondition"

/* creating application global variables */
class GlobalVariables {

    // variable type definition
    public state: state
    public dispatch: dispatch
    private serverURL: string
    private headers: HeadersInit
    public user: string

    // constructing variables
    constructor(state: state, dispatch: dispatch) {
        this.state = state
        this.dispatch = dispatch
        this.serverURL = serverURL
        this.user = encryption.encrypt(getInfo("user", "_id"))
        this.headers = {
            "Content-Type": "application/json",
            "token": this.user,
        }
    }

    // function for handling application input change
    public handleInputChange = (event: React.ChangeEvent<HTMLElement>): void => {
        try {

            // get HTML element name and value from event target
            const { name, value }: any = event.target

            // update application state
            this.dispatch({ type: name, value: { ...this.state, [name]: value } })

        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    // function for POST (creating) and PUT (updating) resource(s) to the server
    public createOrUpdate = async (options: createOrUpdate): Promise<serverResponse> => {
        try {

            // enable loading if necessary
            this.dispatch({ type: "loading", value: { ...this.state, loading: options.loading } })

            // make a post or put request to the server
            const response: serverResponse = await (await fetch(`${this.serverURL}/${options.route}`, {
                method: options.method,
                mode: "cors",
                body: JSON.stringify(options.body),
                headers: this.headers
            })).json()

            // disable loading
            this.dispatch({ type: "loading", value: { ...this.state, loading: false } })

            // returning response
            return response

        } catch (error) {

            // disable loading
            this.dispatch({ type: "loading", value: { ...this.state, loading: false } })
            return { success: false, message: (error as Error).message }

        }
    }

    // function for GET (reading) and DELETE (deleting) resource(s) to the server
    public readOrDelete = async (options: readOrDelete): Promise<serverResponse> => {
        try {

            // enable loading if necessary
            this.dispatch({ type: "loading", value: { ...this.state, loading: options.loading } })
            this.dispatch({ type: "disabled", value: { ...this.state, disabled: options.disabled ? true : false } })

            // make a get or delete request to the server
            const response: serverResponse = await (await fetch(`${this.serverURL}/${options.route}?${options.parameters}`, {
                method: options.method,
                mode: "cors",
                headers: this.headers
            })).json()

            // disable loading
            this.dispatch({ type: "loading", value: { ...this.state, loading: false } })
            this.dispatch({ type: "disabled", value: { ...this.state, disabled: false } })

            // returning response
            return response

        } catch (error) {

            // disable loading
            this.dispatch({ type: "loading", value: { ...this.state, loading: false } })
            this.dispatch({ type: "disabled", value: { ...this.state, disabled: false } })

            return { success: false, message: (error as Error).message }

        }
    }

    // user authentication login and logout
    public authenticate = (action: "login" | "logout", data?: any) => {
        try {

            // checking authentication action
            if (action === 'login' && data) {
                // storing user data in local storage
                storage.store("user", data)

                // updating authenticated state to true
                this.dispatch({ type: "authenticated", value: { ...this.state, authenticated: true } })
                window.location.href = "/dashboard"
                // this.dispatch({ type: "notification", value: { ...this.state, notification: "You have been logged in" } })
            }
            else {
                storage.remove("user")
                this.dispatch({ type: "authenticated", value: { ...this.state, authenticated: false } })
                window.location.href = "/"
                // this.dispatch({ type: "notification", value: { ...this.state, notification: "You have been logged out" } })
            }

        } catch (error) {

            // remove user from local storage
            storage.remove("user")
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })

        }
    }

    // function for retrieving user from local storage and authenticating
    public retrieveUserAndAuthenticate = (): void => {
        try {

            // retrieving user
            const user = storage.retrieve("user")

            // verifying user data exist in local storage
            if (user)
                // updating authenticated state to true
                this.dispatch({ type: "authenticated", value: { ...this.state, authenticated: true } })
            else {
                // updating authenticated state to false
                this.dispatch({ type: "authenticated", value: { ...this.state, authenticated: false } })
            }

        } catch (error) {

            // updating authenticated state to false
            this.dispatch({ type: "authenticated", value: { ...this.state, authenticated: false } })
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })

        }
    }

    // function for opening and closing sidebar
    public toggleSidebar = (): void => {
        try {
            // getting body document element
            const body = document.querySelector("body")

            // checking wether body is available
            if (body) {

                // checking wether sidebar has toggle-sidebar class
                if (body.classList.contains("toggle-sidebar"))
                    // removing class ie: opening sidebar
                    body.classList.remove("toggle-sidebar")

                else
                    // adding class ie: closing sidebar
                    body.classList.add("toggle-sidebar")
            }

        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    // autoclose sidebar on medium screen and below
    public closeSidebar = (): void => {
        try {
            if (window.screen.availWidth <= 1199)
                this.toggleSidebar()
        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    // showing and hiding component
    public toggleComponent(name: "modal" | "dialog"): void {
        try {

            // select component
            const component: HTMLElement | null = document.querySelector(`.${name}`)

            // checking wether component is selected or not
            if (component)
                // toggle show component class
                component.classList.toggle("show")

        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    // component unmounting
    public unMount = (): void => this.dispatch({ type: "all", value: { ...this.state } })

    // load component data on mounting
    public mount = async (options: mount): Promise<serverResponse> => {
        try {

            this.dispatch({ type: "collection", value: { ...this.state, collection: options.collection ? options.collection : "all" } })
            this.dispatch({ type: "schema", value: { ...this.state, schema: options.schema ? options.schema : "all" } })
            this.dispatch({ type: "select", value: { ...this.state, select: options.select ? options.select : {} } })
            this.dispatch({ type: "joinForeignKeys", value: { ...this.state, joinForeignKeys: options.joinForeignKeys ? true : false } })
            this.dispatch({ type: "fields", value: { ...this.state, fields: options.fields ? options.fields : [] } })

            // make api request
            const response: serverResponse = await this.readOrDelete({
                route: options.route,
                loading: true,
                disabled: false,
                parameters: options.parameters,
                method: "GET"
            })

            if (response.success) {

                if (options.route === "api/v1/list-all")
                    this.dispatch({ type: options.collection ? options.collection : "all", value: { ...this.state, [options.collection ? options.collection : "all"]: response.message } })
                else if (options.route === "api/v1/list") {
                    this.dispatch({ type: options.collection ? options.collection : "all", value: { ...this.state, [options.collection ? options.collection : "all"]: response.message.documents } })
                    this.dispatch({ type: "page", value: { ...this.state, page: response.message.currentPage } })
                    this.dispatch({ type: "limit", value: { ...this.state, limit: response.message.limit } })
                    this.dispatch({ type: "nextPage", value: { ...this.state, nextPage: response.message.nextPage } })
                    this.dispatch({ type: "previousPage", value: { ...this.state, previousPage: response.message.previousPage } })
                    this.dispatch({ type: "pages", value: { ...this.state, pages: response.message.totalDocuments } })
                    this.dispatch({ type: "sort", value: { ...this.state, sort: options.sort ? options.sort : "" } })
                    this.dispatch({ type: "condition", value: { ...this.state, condition: options.condition ? options.condition : "" } })
                    this.dispatch({ type: "order", value: { ...this.state, order: options.order ? options.order : 1 } })


                    // pagination
                    this.pagination(response.message)

                }

                return response

            }

            this.dispatch({ type: "condition", value: { ...this.state, condition: options.condition ? options.condition : "" } })
            this.dispatch({ type: "notification", value: { ...this.state, notification: response.message } })
            return response

        } catch (error) {

            // return response
            return { success: false, message: (error as Error).message }

        }
    }

    // searching data to the server
    public searchData = async (event: React.ChangeEvent<HTMLFormElement>): Promise<void> => {
        try {

            // prevent form default submit
            event.preventDefault()

            // cheking wether keyword has been provided
            if (this.state.searchKeyword.trim() !== "") {

                const vendoSharing = window.location.pathname === "/vendor/sharing"

                // search deleted data
                const searchDeleted: object = this.state.condition === "deleted" ? { deleted: true } : { deleted: false }

                // parameters, condition and sort
                const condition: string = JSON.stringify(!vendoSharing ? { ...commonCondition, ...this.state.propsCondition, ...searchDeleted } : searchDeleted)

                const sort: string = JSON.stringify({ createdAt: 1 })
                const select: string = JSON.stringify(this.state.select)
                const parameters: string = `schema=${this.state.schema}&keyword=${text.format(this.state.searchKeyword)}&condition=${condition}&sort=${sort}&select=${select}&joinForeignKeys=${JSON.stringify(this.state.joinForeignKeys)}&fields=${JSON.stringify(this.state.fields)}`

                // request options
                const options: readOrDelete = {
                    route: apiV1 + "search",
                    method: "GET",
                    parameters,
                    loading: true,
                    disabled: false
                }

                // making api request
                const response: serverResponse = await this.readOrDelete(options)

                // checking wether data have been found
                if (response.success) {
                    this.dispatch({ type: this.state.collection, value: { ...this.state, [this.state.collection]: response.message } })
                    this.dispatch({ type: "pageNumbers", value: { ...this.state, pageNumbers: [] } })
                }
                else {
                    this.dispatch({ type: this.state.collection, value: { ...this.state, [this.state.collection]: [] } })
                    this.dispatch({ type: "notification", value: { ...this.state, notification: response.message } })
                }

                // this.dispatch({ type: "ids", value: { ...this.state, ids: [] } })

            }

        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    // filtering data to backend
    public filterData = async (condition: string, order: 1 | -1, sort: string, limit: number): Promise<void> => {
        try {


            if (
                (this.state.condition !== condition) ||
                (this.state.order !== order) ||
                (this.state.sort !== sort) ||
                (this.state.limit !== limit)
            ) {

                // parameters, condition and sort
                const backendSort: string = JSON.stringify({ [sort === "time created" ? "createdAt" : sort.replace(/ /g, "")]: order })
                const backendCondition: string = JSON.stringify({ ...getBackendCondition(condition), ...this.state.propsCondition })
                const select: string = JSON.stringify(this.state.select)
                const parameters: string = `schema=${this.state.schema}&condition=${backendCondition}&sort=${backendSort}&page=${this.state.page}&limit=${limit}&select=${select}&joinForeignKeys=${JSON.stringify(this.state.joinForeignKeys)}`

                // request options
                const options: readOrDelete = {
                    route: `${apiV1}list`,
                    method: "GET",
                    loading: true,
                    disabled: false,
                    parameters
                }

                // making api request
                const response: serverResponse = await this.readOrDelete(options)

                if (response.success) {
                    this.dispatch({ type: this.state.collection, value: { ...this.state, [this.state.collection]: response.message.documents } })
                    this.dispatch({ type: "page", value: { ...this.state, page: response.message.currentPage } })
                    this.dispatch({ type: "limit", value: { ...this.state, limit: response.message.limit } })
                    this.dispatch({ type: "nextPage", value: { ...this.state, nextPage: response.message.nextPage } })
                    this.dispatch({ type: "previousPage", value: { ...this.state, previousPage: response.message.previousPage } })
                    this.dispatch({ type: "pages", value: { ...this.state, pages: response.message.totalDocuments } })
                    this.dispatch({ type: "sort", value: { ...this.state, sort: sort } })
                    this.dispatch({ type: "condition", value: { ...this.state, condition: condition } })
                    this.dispatch({ type: "order", value: { ...this.state, order: order } })
                    this.dispatch({ type: "limit", value: { ...this.state, limit: limit } })

                    // pagination
                    this.pagination(response.message)

                }
                else {
                    this.dispatch({ type: this.state.collection, value: { ...this.state, [this.state.collection]: [] } })
                    this.dispatch({ type: "sort", value: { ...this.state, sort: sort } })
                    this.dispatch({ type: "condition", value: { ...this.state, condition: condition } })
                    this.dispatch({ type: "order", value: { ...this.state, order: order } })
                    this.dispatch({ type: "limit", value: { ...this.state, limit: limit } })
                    this.dispatch({ type: "notification", value: { ...this.state, notification: response.message } })
                    this.dispatch({ type: "page", value: { ...this.state, page: 1 } })
                    this.dispatch({ type: "limit", value: { ...this.state, limit: this.state.limit } })
                    this.dispatch({ type: "nextPage", value: { ...this.state, nextPage: 0 } })
                    this.dispatch({ type: "previousPage", value: { ...this.state, previousPage: 0 } })
                    this.dispatch({ type: "pages", value: { ...this.state, pages: 1 } })
                    this.dispatch({ type: "pageNumbers", value: { ...this.state, pageNumbers: [] } })
                    this.dispatch({ type: "limits", value: { ...this.state, limits: [] } })

                }

                this.dispatch({ type: "ids", value: { ...this.state, ids: [] } })

            }
        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    // paginatint list
    private pagination = (data: any): void => {

        const { pages, limit, currentPage } = data

        // // counter to ensure we create only 10 pages pagination
        const screenWidth: number = window.screen.availWidth
        const screenLimit: number = screenWidth > 992 ? 10 : 5

        // // create page numbers according to screen size
        let pageNumbers: number[] = []

        if (((pages.length <= 10) && (screenLimit === 10)) || ((pages.length <= 5) && screenLimit === 5))
            pageNumbers = pages
        else {

            let counter: number = 0
            for (let index = currentPage; counter < screenLimit; index += 1) {
                if (pages.includes(index))
                    pageNumbers.push(index)
                counter += 1
            }

            if ((pageNumbers.length < 10) && (screenWidth > 992))
                pageNumbers = [...new Set([...this.state.pageNumbers, ...pageNumbers])]
            else if ((pageNumbers.length < 5) && (screenWidth <= 992))
                pageNumbers = [...new Set([...this.state.pageNumbers, ...pageNumbers])]

        }

        // // create limits
        let limits: number[] = []
        for (let index = 0; index <= (pages.length * limit); index += limit)
            limits.push(index)

        // // remove first element in limit, because it is 0
        limits.shift()

        limits = [...new Set([...this.state.limits, ...limits])]

        if (pageNumbers.length > 0)
            this.dispatch({ type: "pageNumbers", value: { ...this.state, pageNumbers } })
        else
            this.dispatch({ type: "pageNumbers", value: { ...this.state, pageNumbers: [] } })

        if (limits.length > 0)
            this.dispatch({ type: "limits", value: { ...this.state, limits } })
        else
            this.dispatch({ type: "limits", value: { ...this.state, limits: [] } })

    }

    // load new page (paginate)
    public paginateData = async (page: number): Promise<void> => {
        if ((page >= 1) && (this.state.page !== page)) {

            // parameters, condition and sort
            const backendSort: string = JSON.stringify({ [this.state.sort === "time created" ? "createdAt" : this.state.sort.replace(/ /g, "")]: this.state.order })
            const backendCondition: string = JSON.stringify({ ...getBackendCondition(this.state.condition), ...this.state.propsCondition })
            const select: string = JSON.stringify(this.state.select)
            const joinForeignKeys: string = JSON.stringify(this.state.joinForeignKeys)
            const parameters: string = `schema=${this.state.schema}&condition=${backendCondition}&sort=${backendSort}&page=${page}&limit=${this.state.limit}&select=${select}&joinForeignKeys=${joinForeignKeys}`

            // request options
            const options: readOrDelete = {
                route: apiV1 + "list",
                method: "GET",
                loading: true,
                disabled: false,
                parameters
            }

            // making api request
            const response: serverResponse = await this.readOrDelete(options)

            if (response.success) {
                this.dispatch({ type: this.state.collection, value: { ...this.state, [this.state.collection]: response.message.documents } })
                this.dispatch({ type: "page", value: { ...this.state, page: response.message.currentPage } })
                this.dispatch({ type: "limit", value: { ...this.state, limit: response.message.limit } })
                this.dispatch({ type: "nextPage", value: { ...this.state, nextPage: response.message.nextPage } })
                this.dispatch({ type: "previousPage", value: { ...this.state, previousPage: response.message.previousPage } })
                this.dispatch({ type: "pages", value: { ...this.state, pages: response.message.totalDocuments } })

                // pagination
                this.pagination(response.message)

            }
            else
                this.dispatch({ type: "notification", value: { ...this.state, notification: response.message } })

            // this.dispatch({ type: "ids", value: { ...this.state, ids: [] } })

        }
    }

    // table list selection
    public selectList = (id?: string): void => {
        try {

            // checking wether id has been provided
            if (id)
                // remove id
                if (this.state.ids.includes(id))
                    this.dispatch({ type: "ids", value: { ...this.state, ids: this.state.ids.filter((listId: string) => listId !== id) } })
                // add new id
                else
                    this.dispatch({ type: "ids", value: { ...this.state, ids: [...this.state.ids, id] } })
            else
                // deselect all
                if ((this.state.ids.length === this.state[this.state.collection].length) && (this.state.ids.length > 0))
                    this.dispatch({ type: "ids", value: { ...this.state, ids: [] } })

                // select all
                else
                    this.dispatch({ type: "ids", value: { ...this.state, ids: this.state[this.state.collection].map((list: any) => list._id) } })

        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    // opening dialog on button click
    public openDialog = async (backendStatus: backendStatus): Promise<void> => {
        try {
            // opening confirmation dialog
            this.toggleComponent("dialog")

            // update state vendor status
            this.dispatch({ type: "backendStatus", value: { ...this.state, backendStatus } })

        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    // exporting excel file
    public arrayToExcel = (template: any[], fileName: string): void => {
        try {
            const workSheet = XLSX.utils.json_to_sheet(template)
            const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] }
            const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' })
            const excelData = new Blob([excelBuffer], { type: 'xlsx' })
            FileSaver.saveAs(excelData, `${applicationName}_${text.format(fileName)}.xlsx`)
        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    // convert excel file to JSON
    public excelToArray = (file: any, callback?: any): void => {
        try {
            const fileReader = new FileReader()
            fileReader.onload = () => {
                const workbook = XLSX.read(fileReader.result, { type: "binary" })
                const workSheetName = workbook.SheetNames[0]
                const workSheet = workbook.Sheets[workSheetName]
                const list = XLSX.utils.sheet_to_json(workSheet)
                this.dispatch({ type: "list", value: { ...this.state, list } })
                if (callback)
                    callback(list)
            }
            fileReader.readAsBinaryString(file)
        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    // get model sort and condition
    public getSortOrCondition = (type: "sort" | "condition"): string[] => {
        let initialConditions: string[] = isAdmin ? [`deleted`, this.state.collection] : [this.state.collection]
        let initialSorts: string[] = ["time created"]
        let conditions;
        let sorts;

        switch (this.state.collection) {
            case "vendors":
                conditions = [
                    ...initialConditions, `approved`, `denied `, "disabled", `requested `, `in progress `, `with balance`, `out of balance`
                ]
                sorts = [
                    ...initialSorts, `name`, `balance`, `status`
                ]
                break

            case "contacts":
                conditions = [...initialConditions]
                sorts = [...initialSorts, "phone Number", "fullName"]
                break

            case "groups":
                conditions = [...initialConditions]
                sorts = [...initialSorts, "name"]
                break

            case "messages":
                conditions = [...initialConditions, "sent", "scheduled", "API"]
                sorts = [...initialSorts, "text", "cost", "status", "scheduled Date"]
                break

            case "users":
                conditions = [
                    ...initialConditions, "with 2FA", "without 2FA", "verified", "not verified"
                ]
                sorts = [...initialSorts, "fullName", "phone Number", "userName", "status", "account Type"]
                break

            case "payments":
                conditions = [
                    ...initialConditions, "incoming", "outgoing", isAdmin ? "outgoing paid" : "incoming paid", "canceled",
                ]
                sorts = [...initialSorts, "status", "total Amount", "type"]
                break

            default:
                conditions = initialConditions
                sorts = initialSorts
        }

        if (type === "condition")
            return conditions

        return sorts

    }

    // updating backend status
    public updateBackendStatus = async (): Promise<void> => {
        try {

            // closing dialog
            this.toggleComponent("dialog")

            // creating request options
            const options: createOrUpdate = {
                route: apiV1 + "bulk-update",
                method: "PUT",
                loading: true,
                body: this.state.ids.map((id: string) => (
                    (this.state.backendStatus === "deleted") || (this.state.backendStatus === "active")
                        ?
                        {
                            schema: this.state.schema,
                            condition: { _id: id },
                            newDocumentData: {
                                updatedBy: getInfo("user", "_id"),
                                deleted: this.state.backendStatus === "deleted" ? true : false
                            }
                        }
                        :
                        {
                            schema: this.state.schema,
                            condition: { _id: id },
                            newDocumentData: {
                                updatedBy: getInfo("user", "_id"),
                                status: text.format(this.state.backendStatus)
                            }
                        }))
            }

            // making api request
            const response: serverResponse = await this.createOrUpdate(options)

            // cheking wether the request succeded
            if (response.success) {

                // creating new data array
                let newList: any[] = []

                // checking wether status is not "deleted"
                if ((this.state.backendStatus !== "deleted") && (this.state.backendStatus !== "active"))

                    // eslint-disable-next-line
                    this.state[this.state.collection].map((data: any) => {
                        // creating new data object
                        let newData = data;

                        response.message.map((updatedData: any) => data._id === updatedData._id ? newData = updatedData : null)
                        // adding updated data to new data array
                        newList.push(newData)

                    })
                else
                    newList = this.state[this.state.collection].filter((data: any) => !response.message.some((deletedData: any) => data._id === deletedData._id))

                this.dispatch({ type: this.state.collection, value: { ...this.state, [this.state.collection]: newList } })
                this.dispatch({ type: this.state.schema, value: { ...this.state, [this.state.schema]: response.message[0] } })
                this.dispatch({ type: "notification", value: { ...this.state, notification: `${this.state.ids.length > 1 ? `${this.state.collection} have` : ` ${this.state.schema} has`} been ${this.state.backendStatus === "active" ? "restored" : this.state.backendStatus === "in_progress" ? "set to in progress" : this.state.backendStatus}` } })
                this.dispatch({ type: "backendStatus", value: { ...this.state, backendStatus: "active" } })
                if (this.state.ids.length > 1)
                    this.dispatch({ type: "ids", value: { ...this.state, ids: [] } })
            }
            else
                this.dispatch({ type: "notification", value: { ...this.state, notification: `Failed to update ${this.state.schema}` } })

        } catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

    /* file handling func */
    public handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        try {

            const files = event.target.files;

            if (files && (files.length > 0)) {
                this.dispatch({ type: "files", value: { ...this.state, files } })
                this.dispatch({ type: "filesError", value: { ...this.state, filesError: "" } })
                this.excelToArray(files[0])
            }
            else
                this.dispatch({ type: "filesError", value: { ...this.state, filesError: "File is required" } })


        }
        catch (error) {
            this.dispatch({ type: "notification", value: { ...this.state, notification: (error as Error).message } })
        }
    }

}

/* exporting Global Variables class */
export default GlobalVariables