/* requiring dependencies */
import React, { Suspense } from "react"
import GlobalVariables from "./helpers/globalVariables";
import reducer from "./hooks/reducer";
import initialState from "./hooks/state";
import { globalVariables, reducerActionTypes } from "./types";
import { BrowserRouter as Router } from "react-router-dom";
import routing from "./routes/routing"
import { AuthLayout, GuestLayout } from "./components/layout";
import Loader from "./components/loader";
import { getInfo, isAdmin, storage } from "./helpers";
import pluralize from "pluralize"

/* creating memorized application functional component */
const App: React.FunctionComponent = React.memo(() => {

  const history = window.history;

  React.useEffect(() => {
    const handlePopstate = (event: any) => {
      // Check if can go back or forward
      if (event.state) {
        // Go back or forward to the previous/next state in History
        history.go(event.state.delta);
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [history]);

  // creating application gloable state
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const myState: any = state
  const user = getInfo("user", "_id")
  const backendModels: reducerActionTypes[] = ["contacts", "groups", "messages", "payments", "users", "vendors"]

  // initiate global variables
  const globalVariables: globalVariables = new GlobalVariables(state, dispatch)

  // remove notification after five seconds
  React.useEffect(() => {
    if (state.notification) {
      setTimeout(() => {
        dispatch({ type: "notification", value: { ...state, notification: "" } })
      }, 3000)
    }
    // eslint-disable-next-line
  }, [state.notification])

  // component mounting
  React.useEffect(() => {

    // authenticate user
    globalVariables.retrieveUserAndAuthenticate()

    if ((state.onlineUsers.length > 1) && isAdmin)
      dispatch({ type: "notification", value: { ...state, notification: `${state.onlineUsers.length} users are online` } })

    // eslint-disable-next-line
  }, [state.onlineUsers])


  // setting online user
  const onlineUser = (id: string): void => {
    try {

      if (id && !state.onlineUsers.includes(id)) {
        const onlineUsers = [id, ...state.onlineUsers]
        const uniqueUsers = [...new Set(onlineUsers)]
        dispatch({ type: "onlineUsers", value: { ...state, onlineUsers: uniqueUsers } })
      }

    } catch (error) {
      dispatch({ type: "notification", value: { ...state, notification: error } })
    }
  }

  // connection
  myState.socket.on("connect", () => {
    console.log("You are connected to real-time connection")
    myState.socket.emit("online", user)
    // dispatch({ type: "notification", value: { ...state, notification: "You are connected to real-time connection" } })
  })

  // on connection error
  myState.socket.on("connect_error", (error: any) => {
    console.log(`Failed to connect to real-time connection due to => ${error}`)
    dispatch({ type: "onlineUsers", value: { ...state, onlineUsers: [] } })
    // dispatch({ type: "notification", value: { ...state, notification: `Failed to connect to real-time connection due to => ${error} ` } })
  })


  myState.socket.on("online-user", (id: any) => onlineUser(id))

  for (let model of backendModels) {

    const schema: any = pluralize.singular(model)

    myState.socket.on(`${schema}-create`, (createdData: any) => {

      onlineUser(createdData.createdBy._id)

      // new array of data
      const newDataArray = [createdData, ...myState[model]]

      if (isAdmin || (createdData?.createdBy?._id === user) || (createdData?.user?._id === user))
        dispatch({ type: model, value: { ...myState, [model]: newDataArray } })

    })

    myState.socket.on(`${schema}-update`, (updatedData: any) => {

      onlineUser(updatedData?.updatedBy?._id)

      // new array data
      const newDataArray = myState[model].filter((data: any) => data._id !== updatedData._id)

      if ((isAdmin || (updatedData?.createdBy?._id === user) || (updatedData?.user?._id === user)) && (!updatedData.deleted))
        dispatch({ type: model, value: { ...myState, [model]: [updatedData, ...newDataArray] } })
      else if ((isAdmin || (updatedData?.createdBy?._id === user) || (updatedData?.user?._id === user)) && (updatedData.deleted))
        dispatch({ type: model, value: { ...myState, [model]: newDataArray } })

      if (myState[schema]?._id === updatedData._id) {
        dispatch({ type: schema, value: { ...myState, [schema]: updatedData } })
        // dispatch({ type: "ids", value: { ...myState, ids: [updatedData._id] } })
      }

      if ((schema === "user") && (updatedData.deleted) && (updatedData._id === user)) {
        storage.clear()
        window.location.href = "/"
      }

    })


    myState.socket.on(`${schema}-delete`, (deletedData: any) => {

      // new array data
      const newDataArray = myState[model].filter((data: any) => data._id !== deletedData._id)

      if ((isAdmin || (deletedData?.createdBy === user) || (deletedData?.user === user)))
        dispatch({ type: model, value: { ...myState, [model]: newDataArray } })

      if (myState[schema]?._id === deletedData._id)
        dispatch({ type: schema, value: { ...myState, [schema]: null } })

      if ((schema === "user") && (deletedData._id === user)) {
        storage.clear()
        window.location.href = "/"
      }

    })

  }

  // returning application component view
  return (
    <Suspense fallback={<Loader />}>
      {state.loading ? <Loader /> : null}
      <Router>
        {
          state.authenticated
            ?
            <AuthLayout
              authenticate={globalVariables.authenticate}
              closeSidebar={globalVariables.closeSidebar}
              toggleSidebar={globalVariables.toggleSidebar}
              unMount={globalVariables.unMount}
              message={state.notification}
            >
              {routing(globalVariables)}
            </AuthLayout>
            :
            <GuestLayout message={state.notification}>
              {routing(globalVariables)}
            </GuestLayout>
        }
      </Router>
    </Suspense>
  )

})

/* exporting application component */
export default App;