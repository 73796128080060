/* requiring dependencies */
import { state } from "../types";
import { socketURL } from "../helpers";

/* creating application initial state */
const initialState: state = {

    // base
    loading: false,
    authenticated: false,
    disabled: false,
    all: true,
    id: "",
    ids: [],
    edit: false,
    notification: "",
    searchKeyword: "",
    schema: "all",
    collection: "all",
    backendStatus: "active",
    files: [],
    file: null,
    filesError: "",
    selected: [],
    propsCondition: {},
    socket: require("socket.io-client")(socketURL, {}),
    onlineUsers: [],

    // user
    account: "",
    accountError: "",
    password: "",
    passwordError: "",
    oldPassword: "",
    oldPasswordError: "",
    code: "",
    codeError: "",
    passwordConfirmation: "",
    passwordConfirmationError: "",
    userName: "",
    userNameError: "",
    phoneNumber: "",
    phoneNumberError: "",
    extraPhoneNumber: "",
    extraPhoneNumberError: "",
    fullName: "",
    fullNameError: "",
    accountType: "",
    accountTypeError: "",
    twoFactorAuthenticationEnabled: "false",
    users: [],
    user: null,
    oldPhoneNumber: "",
    phoneNumberVerified: false,
    apiKey: "",

    // vendor
    vendorName: "",
    vendorNameError: "",
    vendorNameCost: 100000,
    vendors: [],
    vendor: null,
    vendorError: "",
    vendorBalance: 0,
    vendorBalanceError: "",

    // listing
    page: 1,
    pages: 0,
    nextPage: 0,
    previousPage: 0,
    limit: 100,
    sort: "createdAt",
    order: -1,
    condition: "",
    limits: [],
    pageNumbers: [],
    list: [],
    select: {},
    joinForeignKeys: false,
    fields: [],

    // contact
    contact: null,
    contacts: [],
    groupName: "",
    groupNameError: "",
    group: null,
    groups: [],

    // message
    messageText: "",
    messageTextError: "",
    costPerMessage: 40,
    messageCost: 0,
    message: null,
    messages: [],
    scheduled: "false",
    scheduledDate: "",
    scheduledDateError: "",
    tab: "contacts",

    // payment
    payment: null,
    payments: [],
    paymentAmount: 0,
    paymentAmountError: "",
    paymentStatus: "",
    paymentStatusError: "",
    paymentType: "",
    paymentTypeError: "",
    paymentProfit: 0,
    SMSBuyingPrice: 20,

}

/* exporting application intial state  */
export default initialState